<script setup lang="ts">
interface FormFieldOption {
  id: number;
  label?: string;
  value: boolean | string;
  checked: boolean;
}

interface FormField {
  id: number;
  value: string;
  label?: string;
  mandatory?: number;
  layout?: string;
  datatype?: string; // For dropdowns
  options?: FormFieldOption[];
  subLabel?: string;
  height?: string; // For layout='empty'
  checked?: boolean; // For checkboxes
}

const props = defineProps<{
  optinSetupStructureId: string;
  controller?: ReturnType<typeof useModal>;
}>();

const { t } = useI18n();
const { pushSuccess } = useNotifications();

const formFields = reactive<{ structure: FormField[] }>({ structure: [] });

onMounted(async () => {
  await fetchOptinStructureById();
});

async function fetchOptinStructureById() {
  try {
    const response = await fetch("/api/mailingwork/optinSetup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        optinSetupStructureId: props.optinSetupStructureId,
      }),
    });
    const data = await response.json();
    if (data.error === 0) {
      formFields.structure = data?.result?.structure ?? [];
    } else {
      // Handle error
      console.error("Failed to fetch form structure:", data.message);
    }
  } catch (error) {
    console.error("Error fetching form structure:", error);
  }
}

async function submitForm() {
  const formData: any[] = [];

  for (const field of formFields.structure) {
    let fieldData;

    // For input fields like Title, Forename, Surename, Email, and Birthday
    if (["7", "3", "4", "1", "5"].includes(String(field.id))) {
      fieldData = { id: field.id, value: field.value };
      formData.push(fieldData);
    }

    // For a set of Checkboxes
    if (field.id === 9 && Array.isArray(field.options)) {
      const transformedOptions = field.options
        .filter((option: FormFieldOption) => option.checked === true)
        .map((option: FormFieldOption) => {
          return option.value;
        });

      if (transformedOptions.length > 0) {
        fieldData = { id: field.id, value: transformedOptions };
        formData.push(fieldData);
      }
    }

    // For single checkboxes, including profiling checkboxes
    if (field.layout === "checkbox" && field.checked == true) {
      fieldData = { id: field.id, value: "1" };
      formData.push(fieldData);
    }

    // Dropdowns
    if (field.layout === "select" && field.datatype === "enum") {
      fieldData = { id: field.id, value: field.value };
      formData.push(fieldData);
    }
  }

  // Prepare the data in the format expected by the server
  const submissionData = {
    optinSetupId: props.optinSetupStructureId,
    fields: formData,
  };

  // Submit the formData
  try {
    const response = await fetch("/api/mailingwork/optinRecipient", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(submissionData),
    });

    const responseData = await response.json();
    console.log("Form submission response:", responseData);
    pushSuccess(t("newsletter.messages.newsletterSubscribed"));
    props.controller?.close();
  } catch (error) {
    console.error("Error submitting form:", error);
  }
}
</script>

<template>
  <div class="max-w-900px max-h-800px overflow-y-auto overflow-x-hidden p-8">
    <form @submit.prevent="submitForm">
      <div v-for="field in formFields.structure" :key="field.id">
        <!-- Headline -->
        <h2
          v-if="field.layout === 'headline'"
          :id="'field-' + field.id"
          class="text-start text-scheppach-primary-700 text-4xl my-4"
        >
          {{ field.value }}
        </h2>

        <!-- Paragraph -->
        <p
          v-else-if="field.layout === 'paragraph'"
          :id="'field-' + field.id"
          class="my-4"
        >
          {{ field.value }}
        </p>

        <!-- Title Input -->
        <ScheppachTextInput
          v-else-if="field.id === 7"
          v-model="field.value"
          :label="field?.label"
          :required="field?.mandatory == 1"
          class="my-4"
          type="text"
        />

        <!-- Forename Input -->
        <ScheppachTextInput
          v-else-if="field.id === 3"
          v-model="field.value"
          :label="field?.label"
          :required="field?.mandatory == 1"
          autocomplete="given-name"
          type="text"
          class="my-4"
        />

        <!-- Surename Input -->
        <ScheppachTextInput
          v-else-if="field.id === 4"
          v-model="field.value"
          :label="field?.label"
          :required="field?.mandatory == 1"
          autocomplete="family-name"
          type="text"
          class="my-4"
        />

        <!-- Email Input -->
        <ScheppachTextInput
          v-else-if="field.id === 1"
          v-model="field.value"
          :label="field?.label"
          :required="field?.mandatory == 1"
          type="email"
          autocomplete="email"
          class="my-4"
        />

        <!-- Birthday Input -->
        <ScheppachTextInput
          v-else-if="field.id === 5"
          v-model="field.value"
          :label="field?.label"
          :required="field?.mandatory == 1"
          :oninput="() => console.log(field.value)"
          type="date"
          autocomplete="bday"
          class="my-4"
        />

        <!-- Set of Checkboxes -->
        <div v-if="field.id === 9" class="mb-5">
          <template
            v-if="
              field.layout == 'checkbox' || field.layout == 'select multiple'
            "
          >
            <p class="text-scheppach-primary-500 text-2xl font-bold mb-3">
              {{ field.label }}
            </p>
            <div v-for="option in field.options" :key="option.id">
              <ScheppachCheckbox
                v-model="option.checked"
                :label="option.label"
                :required="field?.mandatory == 1"
              />
            </div>
          </template>
        </div>

        <!-- Dropdown -->
        <div v-else-if="field.layout === 'select' && field.datatype == 'enum'">
          <label :for="'field-' + field.id" class="text-scheppach-primary-500">
            {{ field.label }}
          </label>
          <select
            :id="'field-' + field.id"
            v-model="field.value"
            class="sw-search-input placeholder-text-scheppach-neutral-500 text-scheppach-primary-500 bg-scheppach-neutral-50 p-3 outline-none w-full b-b border-scheppach-primary-700 mb-4"
            :required="field?.mandatory == 1"
          >
            <option
              v-for="option in field.options"
              :key="option.id"
              :value="option.value"
            >
              {{ option.label || option.value }}
            </option>
          </select>
        </div>

        <!-- Checkbox -->
        <div v-else-if="field.layout === 'checkbox'" class="my-4">
          <p
            v-if="field.subLabel"
            class="text-scheppach-primary-500 text-2xl font-bold mb-2"
          >
            {{ field.label }} {{ field?.mandatory == 1 ? "*" : "" }}
          </p>
          <div class="flex gap-2">
            <ScheppachCheckbox
              v-model="field.checked"
              :label="field.subLabel ? field.subLabel : field.label"
              :required="field?.mandatory == 1"
            />
          </div>
        </div>

        <!-- Submit Button -->
        <button
          v-else-if="field.layout === 'submit'"
          type="submit"
          class="bg-scheppach-primary-500 hover:bg-scheppach-primary-300 rounded px-5 py-3 uppercase text-sm font-bold c-scheppach-shades-0 my-4"
          :aria-labelledby="'field-' + field.id"
        >
          {{ field.label }}
        </button>

        <!-- Empty Space / Spacer -->
        <div
          v-else-if="field.layout === 'empty'"
          :style="{ height: field.height }"
        ></div>
      </div>
    </form>
  </div>
</template>
